<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="form"
    label-width="100px"
    class="edit-form"
    label-position="top"
    :disabled="submitting"
  >
    <!-- @keyup.enter.native="submitForm('form')" -->
    <el-form-item label="中文名称" prop="nameCn">
      <el-input v-model="form.nameCn" clearable placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="英文名称" prop="nameEn">
      <el-input v-model="form.nameEn" clearable placeholder="请输入"></el-input>
    </el-form-item>
    <el-form-item label="次序" prop="seq">
      <el-input-number
        v-model="form.seq"
        label="请输入"
        :min="1"
      ></el-input-number>
    </el-form-item>
    <el-form-item label="图片" prop="menuImageUrl">
      <SingleImgUpload class="icon-img" v-model="form.menuImageUrl" />
    </el-form-item>

    <el-form-item style="text-align: right">
      <el-button @click="onCancel('form')">关 闭</el-button>
      <el-button
        type="primary"
        @click="submitForm('form')"
        :loading="submitting"
        >{{ submitting ? "保存中..." : "保存" }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { PutMosaicHeightsById, GetMosaicHeightsById } from "../api";
import { ShowApiError } from "@/request/error";
import SingleImgUpload from "@/components/SingleImgUpload";
export default {
  props: {
    sid: {
      type: [String, Number],
      default: null,
    },
  },
  components: { SingleImgUpload },
  data() {
    return {
      currentId: null,
      loading: false,
      submitting: false,
      form: {
        nameCn: "",
        nameEn: "",
        menuImageUrl: "",
        seq: 1,
      },
      rules: {
        nameCn: [{ required: true, message: "请输入", trigger: "blur" }],
        nameEn: [{ required: true, message: "请输入", trigger: "blur" }],
        seq: [
          {
            required: true,
            message: "请输入次序",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    sid: {
      handler(value) {
        if (value !== null) {
          this.currentId = value;
          this.getFormData();
        } else {
          this.currentId = null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutMosaicHeightsById(this.currentId, this.form)
            .then(() => {
              this.$message.success("保存成功!");
              this.initForm();
              this.submitting = false;
              this.getFormData();
              this.emitSucccess();
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("保存错误", err);
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData() {
      this.loading = true;
      GetMosaicHeightsById(this.currentId)
        .then((res) => {
          let { nameCn, nameEn, menuImageUrl, seq } = res.data;
          this.form = {
            nameCn: nameCn,
            nameEn: nameEn,
            menuImageUrl: menuImageUrl,
            seq: seq,
          };
          this.loading = false;
        })

        .catch((err) => {
          this.loading = false;
          ShowApiError(err, "获取表单数据失败");
        });
    },
    onCancel() {
      this.initForm();
      this.emitFail();
    },
    initForm() {
      this.form = {
        nameCn: "",
        nameEn: "",
        menuImageUrl: "",
        seq: 1,
      };
    },
    emitSucccess() {
      this.$emit("success");
    },
    emitFail() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-img {
  width: 100px;
  height: 100px;
}
.edit-form {
  padding: 0 20px;
}
</style>
